import React from 'react'
import { graphql } from 'gatsby'

import Container from 'react-bootstrap/Container'

import Layout from '../components/layout'
import Hero from '../components/Hero'
import ContactForm from '../components/ContactForm'
import SEO from '../components/seo'

const HireUsPage = ({ data, location }) => {
    const hire_us = data.hireUs.edges[0].node

    return( 
        <Layout
          hero={
            <Hero
                image={hire_us.hero_background}
                heading={hire_us.hero_heading}
                subhead={hire_us.hero_subhead}
            />
          }
        >
          <SEO
            title="Hire Us"
            path={location.pathname}
          />
            <section className="bg-gray">

              <Container>
                <ContactForm mainHeading={hire_us.form_contact_heading} projectHeading={hire_us.form_project_heading} />
              </Container>
            </section>
        </Layout>
    )
}

export const query = graphql`
{
  hireUs: allButterPage(filter: { slug: { eq: "hire-us" } }) {
    edges {
      node {
        hero_heading
        hero_subhead
        hero_background
        form_contact_heading
        form_project_heading
        contact_heading
        contact_subhead
        contact_body
      }
    }
  }
}
`

export default HireUsPage
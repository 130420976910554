export const adminEmail = (
  name,
  email,
  phone,
  company,
  location,
  website,
  project_budget,
  project_summary,
  project_type,
  project_timeline
) => `
<html>
<body>
    <h1>New Contact Form Submission<br><small>Alloy</small></h1>
    
    <hr>
    
    <h2>Contact Info:</h2>
    <p>
        Name: ${name}
        <br>Phone: ${phone}
        <br>Email: ${email}
        <br>Company: ${company}
        <br>Location: ${location}
        <br>Website: ${website}
    </p>

    <h2>Project Details</h2>
    <p>
        Project Type: ${project_type}
        <br>Project Budget: ${project_budget}
        <br>Project Summary: ${project_summary}
        <br>Project Timeline: ${project_timeline}
    </p>

    <hr>

    <p>This email was sent from <a href="https://alloy.digital" target="_blank">Alloy</a>.</p>
    <p>
        <strong>Alloy</strong>
        <br>101 25th Street N
        <br>Birmingham, AL 35203
    </p>
</body>
</html>
`
export const userEmail = (
  name,
  email,
  phone,
  company,
  location,
  website,
  project_budget,
  project_summary,
  project_type,
  project_timeline
) => `<html>
  <body
    style="
      background: #efefef;
      color: #222;
      font-family: sans-serif;
      padding: 30px 0;
    "
  >
    <table style="width: 100%">
      <tbody>
        <tr>
          <td style="width: 10%"></td>
          <td
            style="background: #ffffff; padding: 60px 15%; text-align: center"
          >
            <img src="https://alloy.digital/img/favicon.png" width="60" />
            <h1>Thank you for reaching out!</h1>
            <p>
              We're excited to get in touch and hear more about your project.
              Someone from our team will be reaching out to you very soon!
            </p>
            <hr
              style="
                width: 5em;
                border-top: 2px solid #dc232e;
                margin-top: 30px;
                margin-bottom: 30px;
              "
            />
            <table style="text-align: left">
              <thead>
                <tr>
                  <th style="padding: 10px">Name</th>
                  <td style="color: #888; padding: 10px">${name}</td>
                  <th style="padding: 10px">Email</th>
                  <td style="color: #888; padding: 10px">${email}</td>
                </tr>
                <tr>
                  <th style="padding: 10px">Phone</th>
                  <td style="color: #888; padding: 10px">${phone}</td>
                  <th style="padding: 10px">Company</th>
                  <td style="color: #888; padding: 10px">${company}</td>
                </tr>
                <tr>
                  <th style="padding: 10px">Location</th>
                  <td style="color: #888; padding: 10px">${location}</td>
                  <th style="padding: 10px">Website</th>
                  <td style="color: #888; padding: 10px">${website}</td>
                </tr>
              </thead>
            </table>
            <hr style="border-top: 1px solid #efefef" />
            <table style="text-align: left">
              <thead>
                <tr>
                  <th style="padding: 10px">Project Type</th>
                  <td style="color: #888; padding: 10px">${project_type}</td>
                </tr>
                <tr>
                  <th style="padding: 10px">Project Budget</th>
                  <td style="color: #888; padding: 10px">${project_budget}</td>
                </tr>
                <tr>
                  <th style="padding: 10px">Project Summary</th>
                  <td style="color: #888; padding: 10px">${project_summary}</td>
                </tr>
                <tr>
                  <th style="padding: 10px">Project Timeline</th>
                  <td style="color: #888; padding: 10px">
                    ${project_timeline}
                  </td>
                </tr>
              </thead>
            </table>
          </td>
          <td style="width: 10%"></td>
        </tr>
        <tr>
          <td
            style="text-align: center; padding: 30px; color: #666"
            colspan="3"
          >
            <strong
              ><a
                href="https://alloy.digital"
                style="color: #dc232e; text-decoration: none; font-size: 12px"
                >Alloy</a
              ></strong
            >
            <span style="font-size: 10px">
              <br />101 25th Street N <br />Birmingham, AL 35203
            </span>
          </td>
        </tr>
      </tbody>
    </table>
  </body>
</html>
`

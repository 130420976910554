import React, { useState } from "react"

import Row from "react-bootstrap/Row"
import Col from "react-bootstrap/Col"
import Button from "react-bootstrap/Button"
import Form from "react-bootstrap/Form"
import { navigate } from "gatsby"
import { Spinner } from "react-bootstrap"

import { userEmail, adminEmail } from "./contactFormEmails"

const Input = ({
  name,
  label,
  type,
  required,
  formData,
  setFormData,
  invalidFeedback,
}) => {
  const screenReaderLabel = required ? label + " (Required)" : label
  const placeholder = required ? label + " *" : label
  const as = type === "textarea" ? type : "input"

  return (
    <Form.Group controlId={name} key={name}>
      <Form.Label className="sr-only">{screenReaderLabel}</Form.Label>
      <Form.Control
        required={required}
        as={as}
        type={type}
        placeholder={placeholder}
        value={formData[name]}
        onChange={e =>
          setFormData({
            ...formData,
            [name]: e.target.value,
          })
        }
      />
      {invalidFeedback ? (
        <Form.Control.Feedback type="invalid">
          {invalidFeedback}
        </Form.Control.Feedback>
      ) : null}
    </Form.Group>
  )
}

const Select = ({ name, label, required, options, formData, setFormData }) => {
  const screenReaderLabel = required ? label + " (Required)" : label
  const placeholder = required ? label + " *" : label

  return (
    <Form.Group controlId={name} key={name}>
      <Form.Label className="sr-only">{screenReaderLabel}</Form.Label>
      <Form.Control
        as="select"
        onChange={e =>
          setFormData({
            ...formData,
            [name]: e.target.value,
          })
        }
      >
        <option disabled selected>
          {placeholder}
        </option>
        {options.map(option => {
          const selected = formData[name] === option.name ? true : false
          return (
            <option value={option.name} selected={selected}>
              {option.label}
            </option>
          )
        })}
      </Form.Control>
    </Form.Group>
  )
}

const ContactForm = ({ mainHeading, projectHeading }) => {
  const [formData, setFormData] = useState({})
  const [validated, setValidated] = useState(false)
  const [isSubmitting, setSubmitting] = useState(false)

  const handleFormSubmit = e => {
    e.preventDefault()
    const form = e.currentTarget

    if (form.checkValidity() === false) {
      e.stopPropagation()
      setValidated(true)
      return false
    }

    setSubmitting(true)

    const {
      project_budget,
      project_type,
      project_summary,
      project_timeline,
      name,
      email,
      phone,
      company,
      location,
      website,
    } = formData

    var data = {
      name: name,
      company_name: company,
      phone: phone,
      email: email,
      admin: {
        subject: "New Contact Form Submission | Alloy",
        html: adminEmail(
          name,
          email,
          phone,
          company,
          location,
          website,
          project_budget,
          project_summary,
          project_type,
          project_timeline
        ),
      },
      user: {
        html: userEmail(
          name,
          email,
          phone,
          company,
          location,
          website,
          project_budget,
          project_summary,
          project_type,
          project_timeline
        ),
      },
    }

    var formUrl =
      "https://3zr7thjmg5.execute-api.us-east-1.amazonaws.com/production/alloy"

    fetch(formUrl, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then(data => {
        console.log(data)
        navigate(`/hire-us/success/`)
      })
      .catch(e => {
        console.log(e)
        setSubmitting(false)
      })
  }

  return (
    <Form noValidate validated={validated} onSubmit={handleFormSubmit}>
      <section className="w-75 mx-auto pt-0">
        <h2 className="text-center mb-5">{mainHeading}</h2>
        <Row>
          <Col lg="6" sm="12">
            <Input
              formData={formData}
              setFormData={setFormData}
              name="name"
              label="Your Name"
              type="text"
              required={true}
              invalidFeedback="Please provide your name."
            />
          </Col>
          <Col lg="6" sm="12">
            <Input
              formData={formData}
              setFormData={setFormData}
              name="email"
              label="Your Email Address"
              type="email"
              required={true}
              invalidFeedback="Please provide a valid email address."
            />
          </Col>
        </Row>
        <Row>
          <Col lg="6" sm="12">
            <Input
              formData={formData}
              setFormData={setFormData}
              name="location"
              label="Your Location"
              type="text"
            />
          </Col>
          <Col lg="6" sm="12">
            <Input
              formData={formData}
              setFormData={setFormData}
              name="phone"
              label="Your Phone Number"
              type="tel"
              required={true}
              invalidFeedback="Please provide a valid telephone number."
            />
          </Col>
        </Row>
        <Row>
          <Col lg="6" sm="12">
            <Input
              formData={formData}
              setFormData={setFormData}
              name="company"
              label="Your Company Name"
              type="text"
            />
          </Col>
          <Col lg="6" sm="12">
            <Input
              formData={formData}
              setFormData={setFormData}
              name="website"
              label="Your Website"
              type="url"
              invalidFeedback="Please provide a valid web address, starting with http:// or https://."
            />
          </Col>
        </Row>
      </section>
      <section className="w-75 mx-auto p-0">
        <h2 className="text-center mb-5">{projectHeading}</h2>
        <Row>
          <Col lg="6" sm="12">
            <Select
              formData={formData}
              setFormData={setFormData}
              name="project_type"
              label="Type of Project"
              required={true}
              options={[
                { name: "Website/Marketing", label: "Website/Marketing" },
                { name: "Webapp", label: "Webapp" },
                { name: "Intranet", label: "Intranet" },
                { name: "Mobile App", label: "Mobile App" },
                { name: "Hardware", label: "Hardware" },
              ]}
            />
          </Col>
          <Col lg="6" sm="12">
            <Select
              formData={formData}
              setFormData={setFormData}
              name="project_budget"
              label="Approximate budget"
              required={true}
              options={[
                { name: "$5,000-$25,000", label: "$5,000-$25,000" },
                { name: "$25,000-$100,000", label: "$25,000-$100,000" },
                { name: "$100,000-$500,000", label: "$100,000-$500,000" },
                { name: "$500,000-$1,000,000", label: "$500,000-$1,000,000" },
                { name: "$1,000,000+", label: "$1,000,000+" },
              ]}
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12" sm="12">
            <Input
              formData={formData}
              setFormData={setFormData}
              name="project_summary"
              label="Project Summary"
              type="textarea"
            />
          </Col>
        </Row>
        <Row>
          <Col lg="12" sm="12">
            <Input
              formData={formData}
              setFormData={setFormData}
              name="project_timeline"
              label="Approximate Timeline"
              type="textarea"
            />
          </Col>
        </Row>
        <Row className="pb-3 pb-lg-0">
          <Button type="submit" className="mx-auto">
            Submit
            {isSubmitting && (
              <Spinner
                animation="border"
                role="status"
                size="sm"
                className="ml-2"
              >
                <span className="sr-only">Submitting your form</span>
              </Spinner>
            )}
          </Button>
        </Row>
      </section>
    </Form>
  )
}

export default ContactForm
